/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const Item = ({ path, img, label, location, setLocation, ...props }) => {
  return (
    <button
      onClick={() => setLocation(path === location ? 'home' : path)}
      css={css`
        appearance: none;
        font: inherit;
        border: 0;
        pointer-events: all;
        margin: 0 0.2em;
        padding: 0.2em 0.4em 0.1em;
        position: relative;
        background: ${location === path ? 'black' : 'white'};
        color: ${location === path ? 'white' : 'black'};
        border: 2px solid black;
        font-size: ${18 / 16}em;
        line-height: 1;
        font-weight: 700;
        cursor: pointer;
      `}
      {...props}
    >
      {label}
    </button>
  );
};

const Nav = ({ location, setLocation, ...props }) => {
  return (
    <div
      css={css`
        position: fixed;
        z-index: 100;
        top: 1em;
        left: 1em;
        right: 1em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        pointer-events: none;
      `}
      {...props}
    >
      <div
        css={css`
          flex: 0 0 100%;
          display: block;
          margin: 0.1em 0.1em 1em;
          padding: 0;
          color: black;
          font-size: ${22 / 16}em;
          line-height: 1;
          text-align: center;
          font-weight: 700;
        `}
      >
        Chiara’s und Christian’s
        <br />
        Hüttenplausch
      </div>
      <Item
        path="was"
        location={location}
        setLocation={setLocation}
        label="Was"
      />
      <Item
        path="wann"
        location={location}
        setLocation={setLocation}
        label="Wann"
      />
      <Item
        path="wo"
        location={location}
        setLocation={setLocation}
        label="Wo"
      />
      <Item
        path="anmeldung"
        location={location}
        setLocation={setLocation}
        label="Anmeldung"
      />
    </div>
  );
};

export default Nav;
