/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import bgImg from '../assets/img/bg.jpg';

function ToAlbikon({ bg, ...props }) {
  return (
    <div
      css={css`
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: url(${bgImg});
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: stretch;
      `}
      {...props}
    >
      <div
        css={css`
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background: url(${bg
            ? bg
            : 'https://media3.giphy.com/media/xEBZR96wLedVHzOeqw/giphy.gif'});
          background-size: cover;
          background-position: center;
          pointer-events: none;
          mix-blend-mode: hard-light;
        `}
      />
    </div>
  );
}

export default ToAlbikon;
