/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useCallback, useRef, useEffect } from 'react';

import Select from './Select';

const questions = {
  me: {
    depends: null,
    phrases: ['Ich/wir, ', 'input:', ','],
  },
  coming: {
    depends: { me: [['!']] },
    phrases: [
      'komme/n',
      [
        'alleine',
        'zu zweit',
        'zu dritt',
        'zu viert',
        'zu fünft',
        'leider nicht',
      ],
      'zum Hüttenplausch',
    ],
  },
  pitty: {
    depends: { coming: [['leider nicht'], ['!']] },
    phrases: ['Sooooo schaaaaade'],
    actions: ['abschicken'],
  },
  eater: {
    depends: {
      coming: [['!leider nicht'], ['!']],
    },
    phrases: ['ich bin', ['vegi', 'unkompliziert']],
  },
  activity: {
    depends: { eater: [['!']] },
    phrases: [
      'Wenn ich schon komme,',
      [
        'mache ich einfach Party',
        'singe ich ein Lied',
        'bringe ich einen Schnaps',
        'bringe ich einen Kuchen',
        'bringe ich einen Kasten Bier',
        'mache ich DJ',
        'mache ich eine Showeinlage',
        'baue ich einen Schneemann',
        'baue ich eine Schneefrau',
      ],
    ],
  },
  submit: {
    depends: { activity: [['!']] },
    phrases: ['top!'],
    actions: ['abschicken'],
  },
};

const checkDependency = (questionKey, answers) => {
  const question = questions[questionKey];
  if (!question) return false;
  if (!question.depends) return true;
  const dependencyKeys = Object.keys(question.depends).filter(
    (k) => Object.keys(answers).indexOf(k) !== -1
  );
  if (dependencyKeys.length !== Object.keys(question.depends).length)
    return false;

  let valid = true;
  // lets check for AND over multiple dependant keys
  dependencyKeys.forEach((dependencyKey) => {
    // lets check for AND in a single dependant key
    question.depends[dependencyKey].forEach((nested) => {
      // check for OR
      if (
        !nested.find((value) => {
          if (value.trim().indexOf('!') === 0) {
            // NOT
            return answers[dependencyKey].indexOf(value.substring(1)) === -1;
          }
          return answers[dependencyKey].indexOf(value) !== -1;
        })
      ) {
        valid = false;
      }
    });
  });
  return valid;
};

const cleanAnswers = (answers) => {
  Object.keys(questions).forEach((k) => {
    if (answers[k] && !checkDependency(k, answers)) {
      delete answers[k];
      return cleanAnswers(answers);
    }
  });
  return answers;
};

const answersToTxt = (answers) => {
  return Object.keys(answers)
    .map((k) => answers[k].join(' '))
    .join('\r\n');
};

const Answer = ({ id, answers, onPick, onSubmit }) => {
  const myAnswers = useRef([]);
  const [isShown, setIsShown] = useState(false);

  const initAnswers = useCallback(() => {
    myAnswers.current = questions[id]['phrases'].map((phr) =>
      typeof phr === 'object' ? '' : phr.indexOf('input:') === 0 ? '' : phr
    );
  }, [id, myAnswers]);

  useEffect(() => {
    initAnswers();
  }, [initAnswers]);

  useEffect(() => {
    if (isShown) return;
    initAnswers();
  }, [initAnswers, isShown]);

  useEffect(() => {
    setIsShown(!!checkDependency(id, answers));
  }, [id, answers, setIsShown]);
  return (
    isShown && (
      <div
        css={css`
          font-size: ${25 / 16}em;
          line-height: ${30 / 25};
        `}
      >
        {questions[id]['phrases'].map((phr, i) =>
          typeof phr === 'object' ? (
            <Select
              key={i}
              options={phr}
              onChange={(evt) => {
                myAnswers.current[i] = evt.target.value;
                onPick(id, myAnswers.current);
              }}
              defaultValue={myAnswers.current[i]}
            />
          ) : phr.indexOf('input:') === 0 ? (
            <input
              key={i}
              type="text"
              placeholder="mein Name"
              css={css`
                appearance: none;
                border-radius: 0;
                padding: 0;
                margin: 0;
                font: inherit;
                border: 0;
                border-bottom: 1px solid currentColor;
                background: transparent;
                display: inline;
                width: auto;
                color: #0b26fb;
              `}
              onChange={(evt) => {
                myAnswers.current[i] = evt.target.value;
                onPick(id, myAnswers.current);
              }}
            ></input>
          ) : (
            <span key={i}>{phr}</span>
          )
        )}
        {!!questions[id]['actions'] &&
          questions[id]['actions'].map((action, i) => (
            <button
              key={i}
              onClick={onSubmit}
              css={css`
                display: block;
                appearance: none;
                font: inherit;
                border: 0;
                border-radius: 0;
                pointer-events: all;
                margin: 1em 0 0;
                padding: 0.2em 0.4em;
                position: relative;
                background: #0b26fb;
                color: white;
                font-weight: 700;
              `}
            >
              {action}
            </button>
          ))}
      </div>
    )
  );
};

const Quiz = ({ options, setBg, ...props }) => {
  const [answers, setAnswers] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = useCallback(() => {
    const xhr = new XMLHttpRequest(),
      data = new FormData();
    data.append('txt', answersToTxt(answers));

    xhr.addEventListener('load', (evt) => {
      if (xhr.status !== 200) {
        setBg('https://media1.giphy.com/media/KHJw9NRFDMom487qyo/giphy.gif');
        requestAnimationFrame(() =>
          requestAnimationFrame(() => alert('Mist, irgendwas lief schief!'))
        );
        return;
      }
      setBg('https://media3.giphy.com/media/lGZYMRN1r3qqIhy7iu/giphy.gif');
      setIsSubmitted(true);
    });
    xhr.addEventListener('error', () => {
      alert('Mist, irgendwas lief schief!');
    });
    xhr.open('POST', './receive.php');
    xhr.send(data);
  }, [answers, setIsSubmitted, setBg]);

  const onPick = useCallback(
    (key, answer) => {
      switch (key) {
        case 'coming':
          if (answer.indexOf('leider nicht') !== -1) {
            setBg('https://media3.giphy.com/media/1BXa2alBjrCXC/giphy.gif');
          } else if (answer[3] === '') {
            setBg(
              'https://media1.giphy.com/media/xT5LMFZDsj0AKUDYTS/giphy.gif'
            );
          } else {
            if (answer.indexOf('alleine') !== -1) {
              setBg('https://media3.giphy.com/media/JltOMwYmi0VrO/giphy.gif');
            } else if (answer.indexOf('zu zweit') !== -1) {
              setBg(
                'https://media0.giphy.com/media/WYyvz9PIhjLHgiyvR2/giphy.gif'
              );
            } else if (answer.indexOf('zu dritt') !== -1) {
              setBg(
                'https://media3.giphy.com/media/hs3qvWPf6X4fWP1cwk/giphy.gif'
              );
            } else if (answer.indexOf('zu viert') !== -1) {
              setBg(
                'https://media1.giphy.com/media/VABdeNTy88gT0mFJfy/giphy.gif'
              );
            } else {
              setBg('https://media1.giphy.com/media/vQqeT3AYg8S5O/giphy.gif');
            }
          }
          break;

        case 'eater':
          setBg(
            answer.indexOf('vegi') !== -1
              ? 'https://media4.giphy.com/media/30MPjZUuRDsMo/giphy.gif'
              : 'https://media4.giphy.com/media/SasDDqOSRclNu/giphy.gif'
          );
          break;

        case 'activity':
          setBg(
            answer.indexOf('bringe ich einen Schnaps') !== -1
              ? 'https://media2.giphy.com/media/KctNhiy99LoLBTgLNO/giphy.gif'
              : answer.indexOf('singe ich ein Lied') !== -1
              ? 'https://media0.giphy.com/media/vOxiC0rt7hbs4/giphy.gif'
              : answer.indexOf('bringe ich einen Kasten Bier') !== -1
              ? 'https://media4.giphy.com/media/9GJ2w4GMngHCh2W4uk/giphy.gif'
              : answer.indexOf('mache ich einfach Party') !== -1
              ? 'https://media2.giphy.com/media/o75ajIFH0QnQC3nCeD/giphy.gif'
              : answer.indexOf('bringe ich einen Kuchen') !== -1
              ? 'https://media0.giphy.com/media/4RTIdcR5oQBpwEv8TC/giphy.gif'
              : answer.indexOf('mache ich DJ') !== -1
              ? 'https://media3.giphy.com/media/35MGL43SrGfB0WaWJr/giphy.gif'
              : answer.indexOf('mache ich eine Showeinlage') !== -1
              ? 'https://media2.giphy.com/media/P2Hy88rAjQdsQ/giphy.gif'
              : answer.indexOf('baue ich einen Schneemann') !== -1
              ? 'https://media2.giphy.com/media/UFj1TyExcuKTHm26RJ/giphy.gif'
              : answer.indexOf('baue ich eine Schneefrau') !== -1
              ? 'https://media3.giphy.com/media/8HxELmTgWySR2/giphy.gif'
              : 'https://media1.giphy.com/media/tGokBeqpHuSgE/giphy.gif'
          );
          break;

        default:
          break;
      }

      setAnswers(cleanAnswers({ ...answers, [key]: answer }));
    },
    [answers, setAnswers, setBg]
  );

  return (
    <div
      css={css`
        padding-top: ${50 / 16}em;
      `}
      {...props}
    >
      {isSubmitted ? (
        <div>Top, hat geklappt!</div>
      ) : (
        Object.keys(questions).map((key) => {
          return (
            <Answer
              key={key}
              id={key}
              answers={answers}
              onPick={onPick}
              onSubmit={onSubmit}
            />
          );
        })
      )}
    </div>
  );
};

export default Quiz;
