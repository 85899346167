/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import SubNav, { Tab } from './SubNav';
import { P } from './NoteBlock';

function Map(props) {
  const [tab, setTab] = useState('plausch');
  return (
    <div {...props}>
      <SubNav>
        <Tab label="Plausch" path="plausch" location={tab} setTab={setTab} />
        <Tab label="Programm" path="programm" location={tab} setTab={setTab} />
      </SubNav>
      {tab === 'plausch' && (
        <P>
          Aus Plausch laden wir zu einem Winter-Hüttenplausch in der Lenzerheide
          ein. Wir freuen uns auf ein Wochenende unter Freunden in
          Lager-Hütten-Atmosphäre mit Skifahren, Wandern, Schlitteln,
          Schneemann/frau bauen… was immer du gerne machst. Gerne hätten wir
          dich mit Partnerin und Partner und Kind/Kinder dabei.
        </P>
      )}
      {tab === 'programm' && (
        <div>
          <P>
            <strong>Freitag 20.01.23</strong>
            <br />
            • Ankommen möglich ab 17.00 <br />
            • 19.30 Znacht
            <br />
          </P>
          <P>
            <strong>Samstag 21.01.23</strong>
            <br />
            • 08.00 Frühstück
            <br />
            • freie Tagesgestaltung (Wir sind auf der Piste anzutreffen)
            <br />
            • 19.00 Znacht
            <br />
            • Abendprogramm nach Lust und Laune (typische
            Lagerhütten-Unterhaltungsmöglichkeiten wie Tischtennis oder Disco
            sind verfügbar, Karaoke: mit Voranmeldung)
            <br />
          </P>
          <P>
            <strong>Sonntag 22.01.2023</strong>
            <br />
            • 08.00 Frühstück
            <br />
            • Zimmerräumung bis 12.00 (der Parkplatz beim Lagerhaus steht bis
            abends zur Verfügung).Das Gepäck kann im Haus zwischen gelagert
            werden bis zur Abfahrt.
            <br />
            • Freie Tagesgestaltung <br />
          </P>
        </div>
      )}
    </div>
  );
}

export default Map;
