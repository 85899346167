/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

function Date(props) {
  return (
    <div {...props}>
      <p
        css={css`
          font-size: ${43 / 16}em;
          line-height: ${55 / 43};
          font-weight: 700;
        `}
      >
        Freitag 20.01. ab 17:00 bis Sonntag 22.01.2023
      </p>
    </div>
  );
}

export default Date;
