/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const Tab = ({ label, path, location, setTab, ...props }) => {
  return (
    <div
      role="button"
      onClick={() => setTab(path)}
      css={css`
        cursor: pointer;
        display: flex;
        align-items: center;
        text-decoration: none;
        background: ${location === path ? 'black' : 'transparent'};
        color: ${location !== path ? 'black' : 'white'};
        padding: 0 1.2em;
        font-weight: 700;
        &:hover {
          text-decoration: underline;
        }
      `}
      {...props}
    >
      {label}
    </div>
  );
};

function SubNav({ ...props }) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-around;
        height: ${50 / 16}em;
      `}
      {...props}
    />
  );
}

export default SubNav;
