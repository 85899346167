/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const P = (props) => (
  <p
    css={css`
      font-size: ${18 / 16}em;
      line-height: ${25 / 18};
    `}
    {...props}
  />
);

function NoteBlock({ children, ...props }) {
  return (
    <div
      css={css`
        position: absolute;
        z-index: 900;
        bottom: 1vh;
        left: 50%;
        transform: translate(-50%, 0);
        background: white;
        color: black;
        width: 90vw;
        height: 80vh;
        max-width: ${306 / 16}em;
        max-height: ${400 / 16}em;
        background: repeating-linear-gradient(
          #fff 0px,
          #fff 10px,
          #f4f4f4 10px,
          #f4f4f4 11px
        );
        overflow: auto;
        padding: ${20 / 16}em;
        padding-top: 0;
        border-top: ${15 / 16}em solid black;
      `}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          color: #000;
          box-sizing: border-box;
          hyphens: auto;
        `}
      >
        <div
          css={css`
            position: relative;
          `}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default NoteBlock;
