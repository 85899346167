/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const Switch = ({ isActive, children, ...props }) => {
  return (
    !!isActive && (
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: ${isActive ? 'all' : 'none'};
          opacity: ${isActive ? 1 : 0};
          transition: all linear 0.1s;
        `}
        {...props}
      >
        {children}
      </div>
    )
  );
};

export default Switch;
