/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import SubNav, { Tab } from './SubNav';
import { P } from './NoteBlock';

function Map(props) {
  const [tab, setTab] = useState('ort');
  return (
    <div {...props}>
      <SubNav>
        <Tab label="Ort" path="ort" location={tab} setTab={setTab} />
        <Tab
          label="Unterkunft"
          path="unterkunft"
          location={tab}
          setTab={setTab}
        />
      </SubNav>
      {tab === 'ort' && (
        <>
          <P>
            Gruppenferienhaus Raschainas
            <br /> Voa Tgantieni 2A
            <br /> CH – 7078 Lenzerheide
          </P>
          <P>
            <a
              href="https://www.google.ch/maps/place/Ferienhaus+Raschainas/@46.7138435,9.5344913,352m/data=!3m2!1e3!4b1!4m5!3m4!1s0x4784958310429bf3:0xdd44530d78b2a1d5!8m2!3d46.7138435!4d9.5357177?shorturl=1"
              target="_blank"
              rel="noreferrer"
            >
              Google Map
            </a>
          </P>
        </>
      )}
      {tab === 'unterkunft' && (
        <P>
          Gruppenferienhaus Raschainas
          <br />
          <br />
          • Zimmer: wenige Doppelzimmer und unterschiedlich grosse Massenschläge
          mit Duschen und WC’s auf der Etage. Überlegungen zur Zimmereinteilung
          erfolgt durch uns. <br />
          • Grosse Küche und grosser Essbereich
          <br />
          • Unterhaltungsraum
          <br />
          • Grosse Terrasse mit wunderschöner Aussicht
          <br />
          • Parkplätze vor dem Hause
          <br />
          • Das Haus befindet sich an der Piste, eine direkte Zufahrt mit den
          Skis ist möglich
          <br />
          • Wandermöglichkeiten in unmittelbarerer Nähe
          <br />
          <br />
          <a
            href="https://www.raschainas.ch/gruppenferienhaus"
            target="_blank"
            rel="noreferrer"
          >
            Link zur Unterkunft
          </a>
          <br />
          <br />
          Für einen Unkostenbeitrag im Rahmen von 80.- pro Erwachsener für das
          ganze Wochenende sind wir dankbar (2 Nächte mit Frühstück und
          Abendessen).
        </P>
      )}
    </div>
  );
}

export default Map;
